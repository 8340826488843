import { Tune as TuneIcon } from '@material-ui/icons';
import { Badge, Button } from 'antd';
import React, { FC } from 'react';

import { useQueryFilters } from '../../../hooks';

type Props = {
  onClick?: () => void;
};

export const FiltersToggle: FC<Props> = ({ onClick }) => {
  const { selectedFiltersCount } = useQueryFilters();

  return (
    <Badge count={selectedFiltersCount} size="small">
      <Button
        icon={<TuneIcon fontSize="small" />}
        shape="circle"
        onClick={onClick}
      />
    </Badge>
  );
};
