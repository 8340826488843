import styled from 'styled-components';

export const TriggerWrapper = styled.div`
  cursor: pointer;

  & > svg {
    margin-left: 5px;
    position: relative;
    top: 5px;
  }

  &:hover {
    opacity: 0.7;
  }
`;
