import { Typography } from 'antd';
import React, { FC } from 'react';

import { Media, Note, SearchedMedia } from 'types';
import { formatDate } from 'utils';

import { Wrapper } from './styles';

export type MediaMainDataType = Media | Note | SearchedMedia;

type Props = {
  item: MediaMainDataType;
  titleProps?: {
    strong?: boolean;
  };
  onClick?: () => void;
};

export const MediaMainData: FC<Props> = ({ item, titleProps, onClick }) => (
  <Wrapper onClick={onClick}>
    <Typography.Text {...titleProps}>{item.title}</Typography.Text>

    {item.originalTitle ? (
      <>
        <br />
        <Typography.Text type="secondary">
          {item.originalTitle}
          {item.releaseDate && `, ${formatDate(item.releaseDate, 'YYYY')}`}
        </Typography.Text>
      </>
    ) : (
      item.releaseDate && (
        <Typography.Text type="secondary">
          {' '}
          • {formatDate(item.releaseDate, 'YYYY')}
        </Typography.Text>
      )
    )}
  </Wrapper>
);
