import styled from 'styled-components';

import { MediaScoreShape, MediaScoreSize } from './index';

export const getScoreColor = (score?: number) => {
  if (!score && score !== 0) {
    return '';
  }

  if (score === 0) {
    return '#ababab';
  } else if (score < 4) {
    return '#f11515';
  } else if (score >= 4 && score < 6) {
    return '#ff7b31';
  } else if (score >= 6 && score < 7) {
    return '#ffb000';
  }

  return '#37c503';
};

export const MediaScoreStyled = styled.div<{
  value?: number;
}>`
  color: ${(props) => getScoreColor(props.value)};
`;

export const ScoreStyled = styled.div<{
  value?: number;
  size?: MediaScoreSize;
  shape?: MediaScoreShape;
  withIcon?: boolean;
  withBg?: boolean;
  withQuestionWhenNotDefined?: boolean;
}>`
  border-radius: 2px;
  font-weight: 500;

  ${(props) => {
    const color = getScoreColor(props.value);

    if (props.withBg) {
      return `
        color: #fff;
        background-color: ${color};
      `;
    } else {
      return `
        color: ${color};
      `;
    }
  }}

  ${(props) => {
    switch (props.size) {
      case MediaScoreSize.medium:
        return `
          font-size: 1.3rem;
        `;
      case MediaScoreSize.small:
      default:
        return `
          font-size: .75rem;
        `;
    }
  }}

  ${(props) => {
    if (!props.withQuestionWhenNotDefined) {
      return ``;
    }

    return `
      width: 40px;
      height: 40px;
    `;
  }}

  ${(props) => {
    if (!props.withIcon) {
      return ``;
    }

    return `
      display: flex;
      align-items: center;
    `;
  }}

  ${(props) => {
    switch (props.shape) {
      case MediaScoreShape.line:
        return `
          width: inherit;
          height: inherit;
        `;
      case MediaScoreShape.rectangle:
        return `
          padding: 4px 10px;
        `;
      case MediaScoreShape.square:
      default:
        return `
          padding: 10px;
          
          display: flex;
          align-items: center;
          justify-content: center;
        `;
    }
  }}
`;
