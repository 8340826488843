import { NoteStatusesRU } from 'constants/note.constants';
import { FilterDefinitionType, NoteStatus } from 'enums';
import { FilterDefinition } from 'types';

export enum NoteListTab {
  All = 'all',
  InPlans = 'in-plans',
  InProgress = 'in-progress',
  Done = 'done',
  NotInPlans = 'not-in-plans',
  Favorites = 'favorites',
}

export const NOTE_LIST_STATUS_MAP = {
  [NoteListTab.All]: 0,
  [NoteListTab.InPlans]: NoteStatus.InPlans,
  [NoteListTab.InProgress]: NoteStatus.InProgress,
  [NoteListTab.Done]: NoteStatus.Done,
  [NoteListTab.NotInPlans]: NoteStatus.NotInPlans,
  [NoteListTab.Favorites]: 0,
};

export const NOTE_LIST_TAB_CONFIG = [
  { key: NoteListTab.All, label: 'Все' },
  { key: NoteListTab.InPlans, label: NoteStatusesRU[NoteStatus.InPlans] },
  { key: NoteListTab.InProgress, label: NoteStatusesRU[NoteStatus.InProgress] },
  { key: NoteListTab.Done, label: NoteStatusesRU[NoteStatus.Done] },
  { key: NoteListTab.NotInPlans, label: NoteStatusesRU[NoteStatus.NotInPlans] },
  { key: NoteListTab.Favorites, label: 'Любимое' },
];

export const NOTE_FILTER_DEFINITIONS: FilterDefinition[] = [
  {
    label: 'Рейтинг',
    name: 'rating',
    type: FilterDefinitionType.Rating,
    initialValue: [0, 10],
  },
  {
    label: 'Год выпуска',
    name: 'year',
    type: FilterDefinitionType.YearRange,
  },
];
