import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Bookmark as BookmarkIcon, Done as DoneIcon } from '@material-ui/icons';

import { NoteStatus } from 'enums';

export const NoteStatusIcons = {
  [NoteStatus.InProgress]: EyeOutlined,
  [NoteStatus.NotInPlans]: EyeInvisibleOutlined,
  [NoteStatus.InPlans]: BookmarkIcon,
  [NoteStatus.Done]: DoneIcon,
};

export const NoteStatusColors = {
  [NoteStatus.InPlans]: '#ffc400',
  [NoteStatus.InProgress]: '#1531ff',
  [NoteStatus.Done]: '#6ae101',
  [NoteStatus.NotInPlans]: '#b2b1b1',
};
