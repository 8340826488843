import { NoteStatus } from 'enums';

export const NoteStatusesRU = {
  [NoteStatus.InPlans]: 'Буду смотреть',
  [NoteStatus.InProgress]: 'Смотрю',
  [NoteStatus.Done]: 'Просмотрено',
  [NoteStatus.NotInPlans]: 'Неинтересно',
};

export const NoteOptions = Object.entries(NoteStatusesRU).map(
  ([value, label]) => ({ value, label }),
);
