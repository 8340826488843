import { FavoriteBorderOutlined, FavoriteOutlined } from '@material-ui/icons';
import React, { useCallback } from 'react';

import IconButton from 'styled/icon-button';
import { Note } from 'types';

import { useNoteMutations } from '../../hooks';

type Props = {
  note: Note;
};

export const NoteLike: React.FC<Props> = ({ note }) => {
  const { updateNoteLike } = useNoteMutations();

  const handleLike = useCallback(
    () =>
      updateNoteLike.mutate({
        newLike: !note.like,
        id: note.id,
      }),
    [updateNoteLike, note],
  );

  return (
    <IconButton
      icon={
        note.like ? (
          <FavoriteOutlined style={{ color: '#fca2a2' }} fontSize="small" />
        ) : (
          <FavoriteBorderOutlined
            style={{ color: '#a3a3a3' }}
            fontSize="small"
          />
        )
      }
      onClick={handleLike}
      loading={updateNoteLike.isLoading}
    />
  );
};
