import { Col, Row, Skeleton } from 'antd';
import React from 'react';

export const NotesListSkeleton = () => (
  <Row gutter={[16, 16]}>
    {Array.from({ length: 10 }).map((_, i) => (
      <Col key={i} span={24}>
        <Skeleton title={{ style: { height: 75 } }} paragraph={false} active />
      </Col>
    ))}
  </Row>
);
