import { RouteSegment } from 'enums';

import { MediaTypesRU } from './media';

export const CatalogMediaLinks = Object.entries(MediaTypesRU).map(
  ([value, label]) => ({
    label,
    link: `/${RouteSegment.Catalog}/${value}`,
  }),
);
