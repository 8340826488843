import { forEach, map } from 'lodash';

import { YearRangeValueFormat } from 'constants/form.constants';
import { FilterDefinitionType } from 'enums';
import { FilterDefinition } from 'types';
import { formatDate, getDayjsDate } from 'utils';

export const getRatingMarks = () => {
  let result: Record<number, number> = {};
  for (let i = 0; i < 11; i++) {
    result[i] = i;
  }
  return result;
};

export const beforeFormSet = (
  filters: Record<string, any>,
  definitions: FilterDefinition[],
) => {
  let result = { ...filters };

  forEach(definitions, ({ type, name }) => {
    if (!result[name]) {
      return;
    }

    if (type === FilterDefinitionType.YearRange) {
      result[name] = map(result[name], (date: string) =>
        getDayjsDate(date, YearRangeValueFormat),
      );
    }
  });

  return result;
};

export const beforeSubmit = (
  filters: Record<string, any>,
  definitions: FilterDefinition[],
) => {
  let result = { ...filters };

  forEach(definitions, ({ type, name }) => {
    if (!result[name]) {
      return;
    }

    if (type === FilterDefinitionType.YearRange) {
      result[name] = map(result[name], (date: string) =>
        formatDate(date, YearRangeValueFormat),
      );
    }
  });

  return result;
};
