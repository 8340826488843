import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoWrapper = styled.div`
  margin-top: 10px;
  padding: 10px 0 10px 20px;

  background-color: #f9f9f9;
  border-radius: 8px;
`;
