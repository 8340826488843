import { QueryClientProvider } from '@tanstack/react-query';
// import 'moment/locale/ru';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/ru_RU';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ModalsList } from 'components';
import ScrollToTop from 'components/scroll-to-top';
import 'antd/dist/reset.css';
import { queryClient, ThemeConf } from 'config';
import { UserProvider, ModalProvider } from 'contexts';
import { RoutesConfig } from 'routes';

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <ConfigProvider theme={ThemeConf} locale={locale}>
      <ModalProvider>
        <UserProvider>
          <BrowserRouter>
            <ScrollToTop />
            <ModalsList />
            <RoutesConfig />
          </BrowserRouter>
        </UserProvider>
      </ModalProvider>
    </ConfigProvider>
  </QueryClientProvider>
);
