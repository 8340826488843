import { axiosInstance } from 'config';
import { PAGE_SIZE } from 'constants/pagination.constants';
import { Media } from 'types';
import { capitalizeFirstLetter } from 'utils';

import {
  GetMediaByIdRqParams,
  GetMediaGenresRqParams,
  GetMediaResponse,
  GetMediaRqParams,
  SearchMediaRqParams,
} from './types';
import { getMediaPosterURL } from './utils';

const getMedia = ({
  type,
  page,
  ...rest
}: GetMediaRqParams): Promise<GetMediaResponse> =>
  axiosInstance
    .get(`/${type}/list`, {
      params: {
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
        rating_from: rest.rating?.[0],
        rating_to: rest.rating?.[1],
        year_from: rest.year?.[0],
        year_to: rest.year?.[1],
        genres_ids: rest.genreIds,
        filter_not_interesting: rest.notInteresting,
      },
    })
    .then((data: any) => ({
      media: data[type]?.map((media: any) => {
        const m = { ...media, mediaType: type };
        m.pathToPoster = getMediaPosterURL(m);
        return m;
      }),
      total: data.total,
    }));

const getMediaById = ({ type, id }: GetMediaByIdRqParams): Promise<Media> =>
  axiosInstance.get(`/${type}/${id}`).then((data: any) => {
    const media = {
      id,
      ...data,
      mediaType: type,
    };
    media.pathToPoster = getMediaPosterURL(media);
    return media;
  });

const searchMedia = ({ query, type }: SearchMediaRqParams): Promise<any> =>
  axiosInstance
    .get(`/${type}/search`, {
      params: { query },
    })
    .then((data: any) =>
      data[type].map((media: any) => ({ ...media, mediaType: type })),
    );

const getMediaGenres = ({ type }: GetMediaGenresRqParams) =>
  axiosInstance.get(`/${type}/genres`).then((data: any) =>
    data.genres.map((g: any) => ({
      id: g.id,
      name: capitalizeFirstLetter(g.name), // TODO don't like this
    })),
  );

export const catalogApi = {
  getMedia,
  getMediaById,
  searchMedia,
  getMediaGenres,
};
