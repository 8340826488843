import { StarOutlined } from '@ant-design/icons';
import React from 'react';

import { ScoreStyled } from './styles';

export enum MediaScoreSize {
  small,
  medium,
}

export enum MediaScoreShape {
  square,
  rectangle,
  line,
}

type Props = {
  score?: number;

  size?: MediaScoreSize;
  shape?: MediaScoreShape;

  withIcon?: boolean;
  withBg?: boolean;
  withQuestionWhenNotDefined?: boolean;
};

export const MediaScore: React.FC<Props> = ({
  score,
  size,
  shape,
  withBg,
  withIcon,
  withQuestionWhenNotDefined,
}) => {
  if (!score && !withQuestionWhenNotDefined) {
    return null;
  }

  return (
    <ScoreStyled
      value={score}
      size={size}
      shape={shape}
      withIcon={withIcon}
      withBg={withBg}
      withQuestionWhenNotDefined={withQuestionWhenNotDefined}
    >
      {withIcon && <StarOutlined style={{ fontSize: 12 }} />}
      {score ? score.toFixed(1) : '?'}
    </ScoreStyled>
  );
};
