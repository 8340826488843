import styled from 'styled-components';

// TODO fix skeleton, now i'm using this component only for media-card on browse page
export const Wrapper = styled.div`
  width: 100%;

  padding-top: 150%;

  position: relative;

  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: rgba(0, 0, 0, 0.06);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
`;
