import { Drawer, Menu } from 'antd';
import { FC } from 'react';

import { MediaMainDataType, MediaMainData } from 'features/media';

type Props = {
  open: boolean;
  onClose: () => void;

  item: MediaMainDataType;
  menuProps: any;
};

export const NoteSettingsMobileView: FC<Props> = ({
  item,
  menuProps,
  open,
  onClose,
}) => (
  <Drawer
    key="bottom"
    title={<MediaMainData item={item} />}
    placement="bottom"
    bodyStyle={{
      padding: 0,
    }}
    open={open}
    onClose={onClose}
    closable={false}
  >
    <Menu {...menuProps} />
  </Drawer>
);
