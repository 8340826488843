import { MediaType } from 'enums';
import { MediaList } from 'features/media';

import { useTmdbFilters } from './use-tmdb-filters.hook';

const type = MediaType.Movie;

export const MoviesListView = () => {
  const filterDefinitions = useTmdbFilters(type);
  return <MediaList type={type} filterDefinitions={filterDefinitions} />;
};
