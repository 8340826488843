import { PlusOutlined } from '@ant-design/icons';
import React, { FC } from 'react';

import { NoteStatus } from 'enums';
import IconButton from 'styled/icon-button';

import { NoteStatusColors, NoteStatusIcons } from './constants';

type Props = {
  status?: NoteStatus;
  loading?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onFocus?: () => void;
};

export const SelectedStatus: FC<Props> = ({ status, ...rest }) => {
  let icon = <PlusOutlined />;

  if (status) {
    const color = NoteStatusColors[status];
    const Icon = NoteStatusIcons[status];

    icon = <Icon style={{ color }} fontSize="small" />;
  }

  return <IconButton icon={icon} {...rest} />;
};
