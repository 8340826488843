import React from 'react';
import { Link } from 'react-router-dom';

import { RouteSegment } from 'enums';

import { MediaTypesRU } from './media';

export const AccountMediaLinks = Object.entries(MediaTypesRU)
  .map(([value, label]) => ({
    label,
    link: `/${RouteSegment.User}/${RouteSegment.Notes}/${value}`,
  }))
  .map(({ label, link }) => ({
    key: link,
    label: <Link to={link}>{label}</Link>,
  }));
