import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { InputNumber, Space } from 'antd';
import React, { FC } from 'react';

import IconButton from 'styled/icon-button';

type Props = {
  value?: number;
  onChange?: (value: number | null) => void;
};

export const ProgressNumber: FC<Props> = ({ value, onChange }) => (
  <Space>
    <IconButton
      icon={<RemoveIcon fontSize="small" />}
      onClick={() => onChange?.(Number(value) - 1)}
      disabled={value === 1}
    />

    <InputNumber value={value} onChange={onChange} min={1} />

    <IconButton
      icon={<AddIcon fontSize="small" />}
      onClick={() => onChange?.(Number(value) + 1)}
    />
  </Space>
);
