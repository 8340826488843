import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { camelizeKeys } from 'humps';
import qs from 'qs';

import { authUtils } from 'utils';

const TOKEN_HEADER = 'lenno-user-token';

const apiConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_SERVER_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
};

export const axiosInstance: AxiosInstance = axios.create(apiConfig);

axiosInstance.interceptors.request.use((requestConfig: AxiosRequestConfig) => {
  const token = authUtils.getAccessToken();
  return {
    ...requestConfig,
    headers: {
      ...requestConfig.headers,
      ...(token && { [TOKEN_HEADER]: token }),
    },
  };
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => camelizeKeys(response.data),
  (error: AxiosError) => {
    const status = error?.response?.status;
    if (status === 401) {
      // TODO handle 401
    }
    return Promise.reject(error);
  },
);
