import styled from 'styled-components';

export const TriggerWrapper = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  & > span {
    margin-right: 5px;
  }

  &:hover {
    opacity: 0.7;
  }
`;
