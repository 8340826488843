import {
  useMutation,
  useQueryClient,
  UseMutationOptions,
} from '@tanstack/react-query';
import { notification } from 'antd';
import { useCallback, useMemo } from 'react';

import { notesApi } from 'api/notes';
import { QueryKey } from 'enums';

type Props = {
  updateNoteCommentOptions?: UseMutationOptions<unknown, unknown, unknown>;
  updateNoteSeasonAndEpisode?: UseMutationOptions<unknown, unknown, unknown>;
};

export const useNoteMutations = (props?: Props) => {
  const queryClient = useQueryClient();

  const handleSuccess = useCallback(() => {
    queryClient.invalidateQueries([QueryKey.Notes]);
    notification.success({
      message: 'Изменения сохранены!',
      duration: 1,
    });
  }, [queryClient]);

  const addNotes = useMutation({
    mutationFn: notesApi.addNotes,
    onSuccess: handleSuccess,
    // TODO onMutationError, create hook for useMutation and handle
    // this logic inside this hook
  });

  const updateNoteLike = useMutation({
    mutationFn: notesApi.updateNoteLike,
    onSuccess: handleSuccess,
  });

  const updateNoteStatus = useMutation({
    mutationFn: notesApi.updateNoteStatus,
    onSuccess: handleSuccess,
  });

  const updateNoteComment = useMutation({
    ...props?.updateNoteCommentOptions,
    mutationFn: notesApi.updateNoteComment,
    onSuccess: handleSuccess,
  });

  const updateNoteSeasonAndEpisode = useMutation({
    ...props?.updateNoteSeasonAndEpisode,
    mutationFn: notesApi.updateNoteSeasonAndEpisode,
    onSuccess: handleSuccess,
  });

  const deleteNote = useMutation({
    mutationFn: notesApi.deleteNote,
    onSuccess: handleSuccess,
  });

  return useMemo(
    () => ({
      addNotes,
      updateNoteLike,
      updateNoteStatus,
      updateNoteComment,
      updateNoteSeasonAndEpisode,
      deleteNote,
    }),
    [
      addNotes,
      deleteNote,
      updateNoteComment,
      updateNoteLike,
      updateNoteSeasonAndEpisode,
      updateNoteStatus,
    ],
  );
};
