import dayjs from 'dayjs';

export const formatDate = (
  date: string | dayjs.Dayjs,
  format: string,
): string => {
  if (!date) {
    return '';
  }
  return dayjs(date).format(format);
};

export const getDayjsDate = (date: string, format: string): dayjs.Dayjs =>
  dayjs(date, format);
