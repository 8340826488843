import { Space, Tag } from 'antd';
import { transform } from 'lodash';
import React, { useMemo } from 'react';

import { FilterDefinitionType } from 'enums';
import { FilterDefinition } from 'types';

import { isNonFilterValue, useQueryFilters } from '../../../hooks';

type FilterChip = {
  label: string;
  name: string;
  onClose: () => void;
};

type Props = {
  definitions: FilterDefinition[];
};

export const SelectedFilters: React.FC<Props> = ({ definitions }) => {
  const { filters, resetFilters, resetFilter, resetFilterByValue } =
    useQueryFilters<any>();

  const tags = useMemo(
    () =>
      transform(
        definitions,
        (acc, definition) => {
          const value = filters?.[definition.name];
          if (isNonFilterValue(value)) {
            return;
          }

          const pushValue = (val: string, resetByValue?: boolean) => {
            const label = definition.getDisplayName?.(val) || val;
            const onClose = resetByValue
              ? () => resetFilterByValue(definition.name, val)
              : () => resetFilter(definition.name);

            acc.push({
              onClose,
              label: `${definition.label}${label ? `: ${label}` : ''}`,
              name: definition.name,
            });
          };

          switch (definition.type) {
            case FilterDefinitionType.Genre: {
              value.forEach(pushValue, true);
              return;
            }
            case FilterDefinitionType.YearRange:
            case FilterDefinitionType.Rating: {
              let val =
                value[0] === value[1]
                  ? value[0]
                  : `от ${value[0]} до ${value[1]}`;
              pushValue(val);
              return;
            }
            case FilterDefinitionType.Switch: {
              pushValue(``);
              return;
            }
            default:
              pushValue(value);
          }
        },
        [] as FilterChip[],
      ),
    [definitions, filters, resetFilter, resetFilterByValue],
  );

  if (tags.length === 0) {
    return <span />;
  }

  return (
    <Space size={[8, 8]} wrap>
      <Tag onClose={resetFilters} bordered={false} closable>
        Очистить всё
      </Tag>
      {tags.map((tag) => (
        <Tag
          key={tag.label}
          color="blue"
          bordered={false}
          onClose={tag.onClose}
          closable
        >
          {tag.label}
        </Tag>
      ))}
    </Space>
  );
};
