import styled from 'styled-components';

export const Wrapper = styled.div<{ mobile?: boolean }>`
  display: flex;

  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
`;

export const MediaWrapper = styled.div<{ mobile?: boolean }>`
  flex: 1;
`;

export const DescriptionWrapper = styled(MediaWrapper)<{ mobile?: boolean }>`
  padding: ${(props) => (props.mobile ? '0' : '0 20px')};
`;

export const CloseButtonWrapper = styled.div<{ mobile?: boolean }>`
  position: absolute;

  top: ${(props) => (props.mobile ? '35px' : '20px')};
  right: ${(props) => (props.mobile ? '45px' : '20px')};

  z-index: 1;
`;

export const Description = styled.div`
  margin-top: 10px;
`;
