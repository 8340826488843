import { EditOutlined as EditOutlinedIcon } from '@material-ui/icons';
import { Button, Drawer, Form, Input, Popover, Space, Typography } from 'antd';
import React, { FC, useCallback, useMemo } from 'react';

import { ResponsiveQuery, useResponsive, useToggle } from 'hooks';
import { Note } from 'types';

import { TriggerWrapper } from './styles';
import { useNoteMutations } from '../../hooks';

interface FormModel {
  comment: string;
}

interface Props {
  note: Note;
}

export const NoteComment: FC<Props> = ({ note }) => {
  const mobile = useResponsive(ResponsiveQuery.Mobile);

  const { updateNoteComment } = useNoteMutations();
  const { isOpen, toggle } = useToggle();

  const [form] = Form.useForm<FormModel>();

  const handleEdit = useCallback(() => {
    form.setFieldsValue({
      comment: note.comment,
    });
    toggle();
  }, [form, note.comment, toggle]);

  const handleSubmit = useCallback(async () => {
    const data = await form.validateFields();
    updateNoteComment.mutate({
      ...data,
      id: note.id,
    });
    toggle();
  }, [form, note.id, toggle, updateNoteComment]);

  const CommentForm = useMemo(
    () => () => (
      <Form form={form}>
        <Form.Item name="comment" noStyle>
          <Input.TextArea
            style={{ width: '100%', minWidth: 300 }}
            rows={5}
            allowClear
          />
        </Form.Item>
      </Form>
    ),
    [form],
  );

  const Trigger = useMemo(
    () => () => (
      <TriggerWrapper onClick={handleEdit}>
        <Typography.Text type="secondary">
          {note.comment || 'Добавить заметку'}
        </Typography.Text>
        <EditOutlinedIcon style={{ color: '#a3a3a3' }} fontSize="small" />
      </TriggerWrapper>
    ),
    [note.comment, handleEdit],
  );

  return mobile ? (
    <>
      <Trigger />

      <Drawer
        open={isOpen}
        title={
          <Space size={4} direction="vertical">
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              Заметка к
            </Typography.Text>
            <Typography.Title level={5}>{note.title}</Typography.Title>
          </Space>
        }
        placement="bottom"
        onClose={toggle}
        footer={
          <Button type="primary" onClick={handleSubmit} block>
            Сохранить
          </Button>
        }
        closeIcon={null}
        height={350}
      >
        <CommentForm />
      </Drawer>
    </>
  ) : (
    <Popover
      title={
        <div style={{ padding: 12, paddingBottom: 0 }}>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            Заметка
          </Typography.Text>
        </div>
      }
      content={
        <Space
          style={{ padding: 12, paddingTop: 0 }}
          direction="vertical"
          size={16}
          align="end"
        >
          <CommentForm />
          <Button type="primary" onClick={handleSubmit}>
            Сохранить
          </Button>
        </Space>
      }
      open={isOpen}
      placement="bottomLeft"
      trigger="click"
      onOpenChange={toggle}
    >
      <Trigger />
    </Popover>
  );
};
