import { Col, Row } from 'antd';
import React from 'react';

import { CustomSkeleton } from 'components';
import { PAGE_SIZE } from 'constants/pagination.constants';

export const MediaListSkeleton = () => (
  <Row gutter={[16, 16]}>
    {Array.from({ length: PAGE_SIZE }).map((_, i) => (
      <Col key={i} xs={12} sm={8} md={6}>
        <CustomSkeleton />
      </Col>
    ))}
  </Row>
);
