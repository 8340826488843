import styled from 'styled-components';

export const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const Body = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.header`
  background-color: #fff;

  flex: 0 0 65px;

  position: sticky;
  z-index: 2;
  top: 0;

  .ant-menu {
    line-height: 63px;
  }
`;

export const MainWrapper = styled.main`
  flex: 1;
`;

export const FooterWrapper = styled.footer`
  flex: 0 0 70px;
`;
