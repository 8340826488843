import { Pagination } from 'antd';
import React, { FC } from 'react';

import { PAGINATION_DEFAULT_PARAMS } from 'constants/pagination.constants';

import { usePagination } from '../../hooks';

type Props = {
  total?: number;
};

export const QueryPagination: FC<Props> = ({ total }) => {
  const { pagination, setPagination } = usePagination();

  return (
    <Pagination
      {...PAGINATION_DEFAULT_PARAMS}
      current={pagination.page}
      total={total}
      onChange={setPagination}
    />
  );
};
