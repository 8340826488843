import { Space } from 'antd';
import { FC } from 'react';

import { Tag } from './styles';

export type TagsListIdType = string | number;

export type TagListOption = { value: TagsListIdType; label: string };

type Props = {
  value?: TagsListIdType[];
  onChange?: (value: TagsListIdType[]) => void;
  options: TagListOption[];
};

export const TagsList: FC<Props> = ({ options, value, onChange }) => (
  <Space size={[4, 8]} wrap style={{ maxWidth: 400 }}>
    {options.map((o) => (
      <Tag
        key={o.value}
        checked={Boolean(value?.find((v) => v === o.value))}
        onChange={(checked) =>
          onChange &&
          onChange(
            checked
              ? [...(value ?? []), o.value]
              : (value ?? []).filter((v) => o.value !== v),
          )
        }
      >
        {o.label}
      </Tag>
    ))}
  </Space>
);
