import { Skeleton } from 'antd';
import React from 'react';

import { CustomSkeleton } from 'components';
import { ResponsiveQuery, useResponsive } from 'hooks';

import {
  Description,
  DescriptionWrapper,
  MediaWrapper,
  Wrapper,
} from '../styles';

export const MediaSkeleton = () => {
  const mobile = useResponsive(ResponsiveQuery.Mobile);

  return (
    <Wrapper mobile={mobile}>
      <MediaWrapper>
        <CustomSkeleton width={300} />
      </MediaWrapper>
      <DescriptionWrapper mobile={mobile}>
        <Description>
          <Skeleton active />
        </Description>
      </DescriptionWrapper>
    </Wrapper>
  );
};
