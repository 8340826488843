import { encode, decode } from 'js-base64';
import { omit, omitBy, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PAGE_QUERY } from 'constants/pagination.constants';

const FILTERS_QUERY = 'query';

export const isNonFilterValue = (value: unknown) =>
  Array.isArray(value) ? value.length === 0 : !value;

export function useQueryFilters<T extends object>(): {
  filters: T | null;
  filtersQuery: string; // base64 string with encoded filters
  selectedFiltersCount: number;
  setFilters: (filter: T) => void;
  resetFilters: () => void;
  resetFilter: (name: string) => void;
  resetFilterByValue: (name: string, value: string) => void;
} {
  const [filters, setFilters] = useState<T | null>(null);
  const [filtersQuery, setFiltersQuery] = useState<string>(
    null as unknown as string,
  );

  const selectedFiltersCount = useMemo(
    () => (filters ? Object.keys(filters).length : 0),
    [filters],
  );

  const location = useLocation();
  const navigate = useNavigate();

  const setLocalFilters = useCallback((search: string) => {
    const query = new URLSearchParams(search).get(FILTERS_QUERY) as string;

    let parsedFilters = null;
    try {
      const decodedQuery = query ? decode(query) : null;
      parsedFilters = decodedQuery ? JSON.parse(decodedQuery) : null;
    } catch {}

    setFiltersQuery(query);
    setFilters(parsedFilters);
  }, []);

  const handleSetFilters = useCallback(
    (values: T | null) => {
      const searchParams = new URLSearchParams(location.search);

      const nonEmptyData = omitBy(values, isNonFilterValue);
      if (!isEmpty(nonEmptyData)) {
        searchParams.set(FILTERS_QUERY, encode(JSON.stringify(nonEmptyData)));
        searchParams.delete(PAGE_QUERY);
      } else {
        searchParams.delete(FILTERS_QUERY);
      }

      navigate({ search: searchParams.toString() });
    },
    [location, navigate],
  );

  const resetFilters = useCallback(
    () => handleSetFilters(null),
    [handleSetFilters],
  );

  const resetFilter = useCallback(
    (name: string) => handleSetFilters(omit<T>(filters, [name]) as T),
    [filters, handleSetFilters],
  );

  const resetFilterByValue = useCallback(
    (name: string, value: string) => {
      const values = (filters as any)?.[name];
      if (!Array.isArray(values)) {
        return;
      }
      handleSetFilters({
        ...filters,
        [name]: values.filter((v: string) => v !== value),
      } as T);
    },
    [filters, handleSetFilters],
  );

  useEffect(
    () => setLocalFilters(location.search),
    [location, setLocalFilters],
  );

  return {
    filters,
    filtersQuery,
    selectedFiltersCount,
    setFilters: handleSetFilters,
    resetFilters,
    resetFilter,
    resetFilterByValue,
  };
}
