import React from 'react';

import { useNotes, NoteSettings } from 'features/notes';
import { useUser } from 'hooks';
import { Media, SearchedMedia } from 'types';

type Props = {
  media: Media | SearchedMedia;
  showStatusText?: boolean;
};

export const MediaSettings: React.FC<Props> = ({ media, ...rest }) => {
  const { isLoggedIn } = useUser();
  const { getNoteByMediaId } = useNotes(media.mediaType);

  if (!isLoggedIn) {
    return <> </>;
  }

  const note = getNoteByMediaId(media.id);
  return <NoteSettings {...rest} media={media} note={note} />;
};
