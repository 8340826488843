import { Dropdown, MenuProps, Space, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { NoteStatusesRU } from 'constants/note.constants';
import { MediaType } from 'enums';
import { MediaMainDataType } from 'features/media';
import { useResponsive, useToggle, ResponsiveQuery } from 'hooks';
import { Media, Note, SearchedMedia } from 'types';

import {
  DELETABLE_NOTE_SETTINGS_ITEMS,
  DELETE_NOTE_KEY,
  NOTE_SETTINGS_ITEMS,
} from './constants';
import { NoteSettingsMobileView } from './MobileView';
import { SelectedStatus } from './SelectedStatus';
import { useNoteMutations } from '../../hooks';

type Props = {
  media?: Media | SearchedMedia;
  note?: Note;
  showStatusText?: boolean;
};

export const NoteSettings: React.FC<Props> = ({
  media,
  note,
  showStatusText,
}) => {
  const mobile = useResponsive(ResponsiveQuery.Mobile);

  const noteStatus = note?.status;

  const { addNotes, updateNoteStatus, deleteNote } = useNoteMutations();
  const loading = useMemo(
    () =>
      [
        addNotes.isLoading,
        updateNoteStatus.isLoading,
        deleteNote.isLoading,
      ].some((l) => l),
    [addNotes.isLoading, deleteNote.isLoading, updateNoteStatus.isLoading],
  );

  const { isOpen: isMenuOpen, open, close } = useToggle();

  const handleSelect = useCallback(
    (key: string | MediaType) => {
      if (!note && media) {
        addNotes.mutate({
          type: media.mediaType,
          ids: [Number(media.id)],
          status: Number(key),
        });
        return;
      }

      if (key === DELETE_NOTE_KEY) {
        deleteNote.mutate({
          id: Number(note?.id),
        });
      } else {
        updateNoteStatus.mutate({
          newStatus: Number(key),
          id: Number(note?.id),
        });
      }

      close();
    },
    [note, media, close, addNotes, deleteNote, updateNoteStatus],
  );

  const menuProps: MenuProps = useMemo(
    () => ({
      items: [
        ...NOTE_SETTINGS_ITEMS,
        ...(noteStatus ? DELETABLE_NOTE_SETTINGS_ITEMS : []),
      ],
      selectedKeys: noteStatus ? [String(noteStatus)] : [],
      onSelect: ({ key }) => handleSelect(key),
      selectable: true,
    }),
    [noteStatus, handleSelect],
  );

  return (
    <Space>
      {mobile ? (
        <>
          <SelectedStatus
            status={noteStatus}
            loading={loading}
            onClick={open}
          />
          <NoteSettingsMobileView
            item={(media || note) as MediaMainDataType}
            menuProps={menuProps}
            open={isMenuOpen}
            onClose={close}
          />
        </>
      ) : (
        <>
          {showStatusText && noteStatus && (
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {NoteStatusesRU[noteStatus]}
            </Typography.Text>
          )}

          <Dropdown trigger={['click']} menu={menuProps}>
            <SelectedStatus status={noteStatus} loading={loading} />
          </Dropdown>
        </>
      )}
    </Space>
  );
};
