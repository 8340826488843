import { SearchOutlined, MenuOutlined, LoginOutlined } from '@ant-design/icons';
import { Button, Menu, Space } from 'antd';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Logo } from 'components';
import { CatalogMediaLinks } from 'constants/catalog-media-links.constants';
import { ModalNames } from 'enums';
import {
  useModalContext,
  useResponsive,
  useUser,
  ResponsiveQuery,
} from 'hooks';
import IconButton from 'styled/icon-button';

import { AccountMenu } from './AccountMenu';
import { Wrapper } from './styles';

const menuItems = CatalogMediaLinks.map(({ label, link }) => ({
  key: link,
  label: <Link to={link}>{label}</Link>,
}));

export const Header = () => {
  const location = useLocation();
  const mobile = useResponsive(ResponsiveQuery.Mobile);
  const modalContext = useModalContext();
  const { isLoggedIn } = useUser();

  const navigate = useNavigate();

  const handleLogoClick = () => navigate('/');

  const SearchButton = () => (
    <IconButton
      icon={<SearchOutlined />}
      onClick={() => modalContext.openModal(ModalNames.GlobalSearch)}
    />
  );

  return (
    <Wrapper>
      {mobile ? (
        <>
          <IconButton
            icon={<MenuOutlined />}
            onClick={() => modalContext.openModal(ModalNames.Navigation)}
          />

          <Logo onClick={handleLogoClick} />

          <Space>
            <SearchButton />

            {isLoggedIn ? (
              <AccountMenu />
            ) : (
              <IconButton
                icon={<LoginOutlined />}
                onClick={() => modalContext.openModal(ModalNames.Login)}
              />
            )}
          </Space>
        </>
      ) : (
        <>
          <Space>
            <Logo onClick={handleLogoClick} />

            <Menu
              style={{ width: 450, borderBottom: 0 }}
              mode="horizontal"
              items={menuItems}
              selectedKeys={[location.pathname]}
            />
          </Space>

          <Space>
            <SearchButton />

            {isLoggedIn ? (
              <AccountMenu />
            ) : (
              <Space>
                <Button
                  type="primary"
                  onClick={() => modalContext.openModal(ModalNames.Login)}
                >
                  Войти
                </Button>
                <Button
                  onClick={() => modalContext.openModal(ModalNames.Register)}
                >
                  Зарегистрироваться
                </Button>
              </Space>
            )}
          </Space>
        </>
      )}
    </Wrapper>
  );
};
