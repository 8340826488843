import { createContext, FC, PropsWithChildren, useState } from 'react';

import { ModalNames } from 'enums';

type OpenedModal = {
  name: ModalNames;
  props?: any;
};

export type ModalContextProps = {
  modals: OpenedModal[];

  openModal: (name: ModalNames, props?: any) => void;
  closeModal: (name: ModalNames) => void;
};

export const ModalContext = createContext<ModalContextProps>({
  modals: [],
  openModal: (name: ModalNames, props?: any) => {},
  closeModal: (name: ModalNames) => {},
});

type Props = PropsWithChildren<{}>;

export const ModalProvider: FC<Props> = ({ children }) => {
  const [modals, setModals] = useState<OpenedModal[]>([]);

  const handleOpenModal = (name: ModalNames, props?: any) => {
    setModals((modals) => [{ name, props }, ...modals]);
  };

  const handleCloseModal = (n: ModalNames) => {
    setModals((modals) => modals.filter(({ name }) => name !== n));
  };

  return (
    <ModalContext.Provider
      value={{
        modals,
        openModal: handleOpenModal,
        closeModal: handleCloseModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
