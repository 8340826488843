import { KeyOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Divider, Form, Input, Space, Typography } from 'antd';

import { authApi } from 'api/auth';
import { Logo } from 'components';
import { ModalNames } from 'enums';
import {
  useModalContext,
  useResponsive,
  ResponsiveQuery,
  useUserContext,
} from 'hooks';
import Modal from 'styled/modal';

interface FormModel {
  login: string;
  password: string;
}

export const ModalLogin = () => {
  const userContext = useUserContext();

  const mobile = useResponsive(ResponsiveQuery.Mobile);
  const modalContext = useModalContext();

  const [form] = Form.useForm<FormModel>();

  const login = useMutation({
    mutationFn: authApi.login,
    onSuccess: (data) => {
      userContext.setToken(data.token);
      handleClose();
    },
  });

  const handleClose = () => modalContext.closeModal(ModalNames.Login);

  return (
    <Modal
      width={350}
      onCancel={handleClose}
      footer={null}
      fullScreen={mobile}
      centered={!mobile}
      destroyOnClose
      open
    >
      <Space style={{ width: '100%' }} direction="vertical">
        <Logo type="secondary" />

        <Space style={{ width: '100%' }} direction="vertical" align="center">
          <KeyOutlined
            style={{
              fontSize: 50,
              marginBottom: '20px',
              color: '#00000073',
            }}
          />
          <Typography.Title level={4}>Авторизация</Typography.Title>
          <Typography.Text
            type="secondary"
            style={{
              fontSize: 12,
            }}
          >
            для продолжения введите учетные данные
          </Typography.Text>
        </Space>

        {/* TODO add component with errors */}
        {/*<ErrorComponent/>*/}

        <Form form={form} layout="vertical" onFinish={login.mutate}>
          <Form.Item
            label="Логин"
            name="login"
            rules={[{ required: true, message: 'Логин обязателен!' }]}
          >
            <Input placeholder="user@gmail.com" />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={[{ required: true, message: 'Пароль обязателен!' }]}
          >
            <Input.Password placeholder="******" />
          </Form.Item>

          <Form.Item noStyle>
            <Button
              htmlType="submit"
              type="primary"
              loading={login.isLoading}
              block
            >
              Войти
            </Button>
          </Form.Item>

          <Form.Item noStyle>
            <Divider plain> Нет аккаунта? </Divider>
          </Form.Item>

          <Form.Item noStyle>
            <Button
              onClick={() => {
                handleClose();
                modalContext.openModal(ModalNames.Register);
              }}
              block
            >
              Зарегистрироваться
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};
