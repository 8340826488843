import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Alert, Input, InputRef, Space, Tag } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { catalogApi } from 'api/catalog';
import { DataWrapper } from 'components/DataWrapper';
import { MediaTypesRU } from 'constants/media';
import { MediaType, ModalNames, QueryKey } from 'enums';
import { useModalContext, useResponsive, ResponsiveQuery } from 'hooks';
import Modal from 'styled/modal';

import { SearchResult } from './SearchResult';
import {
  InputWrapper,
  ResultWrapper,
  ContentWrapper,
  CloseButton,
} from './styles';

export const ModalSearchMedia = () => {
  const mobile = useResponsive(ResponsiveQuery.Mobile);
  const modalContext = useModalContext();

  const inputRef = useRef<InputRef>(null);

  useEffect(() => inputRef.current?.focus(), []);

  const [type, setType] = useState<MediaType>(MediaType.Movie);
  const [value, setValue] = useState('');
  const [query, setQuery] = useState('');

  const handleQuery = useDebouncedCallback((v: string) => setQuery(v), 500);

  const handleValueChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      handleQuery(newValue);
    },
    [handleQuery],
  );

  const searchQuery = useQuery({
    queryKey: [QueryKey.Search, type, query],
    queryFn: () =>
      catalogApi.searchMedia({
        query,
        type,
      }),
    enabled: query.length > 3,
  });
  const { data: searchResult } = searchQuery;

  const handleTypeChange = (newType: MediaType) => {
    setType(newType);
    handleValueChange('');
  };

  const handleClose = () => modalContext.closeModal(ModalNames.GlobalSearch);

  useEffect(
    () => () => {
      searchQuery.remove();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Modal
      onCancel={handleClose}
      closable={false}
      footer={null}
      fullScreen={mobile}
      open
    >
      <ContentWrapper mobile={mobile}>
        <InputWrapper>
          <Space direction="vertical">
            <Input
              ref={inputRef}
              name="query"
              prefix={<SearchOutlined />}
              suffix={
                searchQuery.isFetching ? (
                  <LoadingOutlined spin />
                ) : (
                  <CloseButton onClick={handleClose}>закрыть</CloseButton>
                )
              }
              value={value}
              onChange={(e) => handleValueChange(e.target.value)}
              placeholder="Поиск..."
              size="large"
              required
            />

            <Space direction="horizontal">
              {Object.entries(MediaTypesRU).map(([value, label]) => (
                <Tag.CheckableTag
                  key={value}
                  checked={value === type}
                  onClick={() => handleTypeChange(value as MediaType)}
                >
                  {label}
                </Tag.CheckableTag>
              ))}
            </Space>
          </Space>
        </InputWrapper>
        <ResultWrapper>
          <DataWrapper
            queryResult={searchQuery}
            loadingView={
              <Alert
                type="info"
                message="Начните печатать. Минимум 4 символа"
                showIcon
              />
            }
          >
            <SearchResult items={searchResult} />
          </DataWrapper>
        </ResultWrapper>
      </ContentWrapper>
    </Modal>
  );
};
