import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { MainLayout } from 'components';
import { MediaType, RouteSegment } from 'enums';
import { useUser } from 'hooks';

import { AccountView, NotesListView } from './account';
import {
  AnimeListView,
  CatalogView,
  MangaListView,
  MoviesListView,
  ShowsListView,
} from './catalog';
import { NotFoundView } from './errors';

const ProtectedRoute = ({ children }: { children: React.ReactElement }) => {
  const { isLoggedIn } = useUser();
  if (!isLoggedIn) {
    return (
      <Navigate to={`/${RouteSegment.Catalog}/${MediaType.Movie}`} replace />
    );
  }
  return children;
};

const routesConfig = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <Navigate
            to={`/${RouteSegment.Catalog}/${MediaType.Movie}`}
            replace
          />
        ),
      },
      {
        path: RouteSegment.Catalog,
        element: <CatalogView />,
        children: [
          {
            index: true,
            element: (
              <Navigate
                to={`/${RouteSegment.Catalog}/${MediaType.Movie}`}
                replace
              />
            ),
          },
          {
            path: MediaType.Movie,
            element: <MoviesListView />,
          },
          {
            path: MediaType.Show,
            element: <ShowsListView />,
          },
          {
            path: MediaType.Anime,
            element: <AnimeListView />,
          },
          {
            path: MediaType.Manga,
            element: <MangaListView />,
          },
        ],
      },
      {
        path: RouteSegment.User,
        element: (
          <ProtectedRoute>
            <AccountView />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <Navigate
                to={`/${RouteSegment.User}/${RouteSegment.Notes}/${MediaType.Movie}`}
                replace
              />
            ),
          },
          {
            path: `/${RouteSegment.User}/${RouteSegment.Notes}/:mediaType`,
            element: <NotesListView />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundView />,
      },
    ],
  },
];

export const RoutesConfig = () => useRoutes(routesConfig);
