import React from 'react';

import { ModalNavigation } from 'components';
import { ModalNames } from 'enums';
import { ModalLogin, ModalRegister } from 'features/auth';
import { ModalMedia, ModalSearchMedia } from 'features/media';
import { useModalContext } from 'hooks';

export const ModalsNameComponentMap = {
  [ModalNames.Login]: ModalLogin,
  [ModalNames.Register]: ModalRegister,
  [ModalNames.GlobalSearch]: ModalSearchMedia,
  [ModalNames.Media]: ModalMedia,
  [ModalNames.Navigation]: ModalNavigation,
};

export const ModalsList = () => {
  const { modals } = useModalContext();

  return (
    <>
      {modals.map((m) => {
        const ModalComponent = ModalsNameComponentMap[m.name];
        return <ModalComponent key={m.name} {...m.props} />;
      })}
    </>
  );
};
