import { useQuery } from '@tanstack/react-query';
import { Col, List, Row, Tabs } from 'antd';
import React, { useMemo } from 'react';

import { notesApi } from 'api';
import { DataWrapper } from 'components';
import { PAGINATION_DEFAULT_PARAMS } from 'constants/pagination.constants';
import { ModalNames, QueryKey } from 'enums';
import { useMediaType } from 'features/media';
import { NoteRow } from 'features/notes';
import {
  Filters,
  usePagination,
  useQueryFilters,
  useQueryParam,
} from 'features/query-filters';
import { useModalContext } from 'hooks';
import { Note, NoteFiltersModel } from 'types';

import {
  NOTE_FILTER_DEFINITIONS,
  NOTE_LIST_TAB_CONFIG,
  NoteListTab,
} from './constants';
import { NotesListSkeleton } from './NotesListSkeleton';
import { NoteRowWrapper } from './styles';
import { filterNotesByFilters, filterNotesByTab, getNoteNumber } from './utils';

const TAB_QUERY = 'tab';

// TODO NotesList: multiple editing
export const NotesList = () => {
  const modal = useModalContext();

  const mediaType = useMediaType();
  const { pagination, setPagination } = usePagination();

  const notesQuery = useQuery({
    queryKey: [QueryKey.Notes, mediaType],
    queryFn: () => notesApi.getNotes({ type: mediaType }),
  });

  const { queryParam: selectedTab, setQueryParam: setTab } = useQueryParam({
    queryParamKey: TAB_QUERY,
    initialValue: NoteListTab.All,
    resetPagination: true,
  });

  const { filters } = useQueryFilters<NoteFiltersModel>();

  const visibleNotes = useMemo(
    () =>
      filterNotesByFilters(
        filterNotesByTab(notesQuery.data, selectedTab),
        filters,
      ),
    [notesQuery.data, selectedTab, filters],
  );

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Tabs
          activeKey={selectedTab}
          onChange={(tab) => setTab(tab as NoteListTab)}
          items={NOTE_LIST_TAB_CONFIG}
        />
      </Col>

      <Col span={24}>
        {/* TODO NotesList: add different views of row*/}
        <Filters
          filterDefinitions={NOTE_FILTER_DEFINITIONS}
          showSelectedFilters
        />
      </Col>

      <Col span={24}>
        <DataWrapper
          queryResult={notesQuery}
          initialLoadingView={<NotesListSkeleton />}
        >
          <List
            itemLayout="vertical"
            pagination={{
              ...PAGINATION_DEFAULT_PARAMS,
              current: pagination.page,
              onChange: setPagination,
            }}
            dataSource={visibleNotes}
            renderItem={(note: Note, index) => (
              <NoteRowWrapper>
                <NoteRow
                  index={getNoteNumber(index, pagination.page)}
                  note={note}
                  onNameClick={() =>
                    modal.openModal(ModalNames.Media, {
                      mediaId: note.mediaId,
                      type: mediaType,
                    })
                  }
                />
              </NoteRowWrapper>
            )}
          />
        </DataWrapper>
      </Col>
    </Row>
  );
};
