import React from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from './Footer';
import { Header } from './Header';
import {
  Body,
  Content,
  FooterWrapper,
  HeaderWrapper,
  MainWrapper,
} from './styles';

export const MainLayout = () => (
  <Body>
    <HeaderWrapper>
      <Content>
        <Header />
      </Content>
    </HeaderWrapper>

    <MainWrapper>
      <Content style={{ padding: '20px' }}>
        <Outlet />
      </Content>
    </MainWrapper>

    <FooterWrapper>
      <Content>
        <Footer />
      </Content>
    </FooterWrapper>
  </Body>
);
