import { DatePicker, Form, Select, Slider, Switch } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';

import { TagsList } from 'components';
import { FilterDefinitionType } from 'enums';
import { useQueryFilters } from 'features/query-filters';
import { FilterDefinition } from 'types';

import { beforeFormSet, beforeSubmit, getRatingMarks } from './utils';

const marks = getRatingMarks();

type Props = {
  definitions: FilterDefinition[];
  onSubmit?: () => void;
};

export const useQueryFiltersForm = ({ definitions, onSubmit }: Props) => {
  const { filters, setFilters } = useQueryFilters<any>(); // TODO fix any

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(beforeFormSet(filters, definitions));
  }, [definitions, filters, form]);

  const handleSubmit = useCallback(async () => {
    const data = await form.validateFields();
    setFilters(beforeSubmit(data, definitions));
    onSubmit?.();
  }, [definitions, form, onSubmit, setFilters]);

  return {
    onSubmit: handleSubmit,
    component: useMemo(
      () => () => (
        <Form layout="vertical" form={form}>
          {definitions.map((definition) => {
            const props = {
              key: definition.name,
              name: definition.name,
              label: definition.label,
              initialValue: definition.initialValue,
              extra: definition.hint,
            };

            switch (definition.type) {
              case FilterDefinitionType.Rating: {
                return (
                  <Form.Item {...props}>
                    <Slider range min={0} max={10} dots marks={marks} />
                  </Form.Item>
                );
              }
              case FilterDefinitionType.Select: {
                return (
                  <Form.Item {...props}>
                    <Select options={definition.options} />
                  </Form.Item>
                );
              }
              case FilterDefinitionType.YearRange: {
                return (
                  <Form.Item {...props}>
                    <DatePicker.RangePicker picker="year" />
                  </Form.Item>
                );
              }
              case FilterDefinitionType.Genre: {
                return (
                  <Form.Item {...props}>
                    <TagsList options={definition.genres} />
                  </Form.Item>
                );
              }
              case FilterDefinitionType.Switch: {
                return (
                  <Form.Item {...props} valuePropName="checked">
                    <Switch />
                  </Form.Item>
                );
              }
              default: {
                return <> </>;
              }
            }
          })}
        </Form>
      ),
      [definitions, form],
    ),
  };
};
