import { QueryObserverBaseResult } from '@tanstack/react-query';
import { Alert, message } from 'antd';
import { PropsWithChildren, ReactElement, useEffect } from 'react';

import { Loader } from 'components';

const LoadingMessageKey = 'loading_message_key';

type Props<T> = PropsWithChildren<{
  queryResult: QueryObserverBaseResult<T, unknown>;
  initialLoadingView?: ReactElement;
  loadingView?: ReactElement;
  getLoadedData?: (data?: T) => unknown;
}>;

export function DataWrapper<T>({
  queryResult,
  initialLoadingView,
  loadingView,
  children,
  getLoadedData,
}: Props<T>) {
  const { isLoading, isInitialLoading, isFetching, isError, error, data } =
    queryResult;

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (isFetching) {
      messageApi.open({
        key: LoadingMessageKey,
        type: 'loading',
        content: 'Данные обновляются',
        duration: 0,
      });
    } else {
      messageApi.destroy(LoadingMessageKey);
    }
  }, [isFetching, messageApi]);

  if (isInitialLoading) {
    return initialLoadingView ? initialLoadingView : <Loader />;
  }

  if (isLoading) {
    return loadingView ? loadingView : <Loader />;
  }

  if (isError) {
    return <Alert message={String(error)} type="error" showIcon />;
  }

  const loadedData = getLoadedData?.(data) || data;
  if (Array.isArray(loadedData) && loadedData.length === 0) {
    return <Alert message="Туть пусто!" type="info" showIcon />;
  }

  return (
    <>
      {contextHolder} {children}
    </>
  );
}
