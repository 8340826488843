import { Button } from 'antd';
import styled from 'styled-components';

// TODO improve IconButton: use theme color
const IconButton = styled(Button).attrs({
  shape: 'circle',
})`
  background-color: #f6f6f6;

  border: 1px solid transparent;
  box-shadow: none;
`;

export default IconButton;
