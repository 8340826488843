import { EditOutlined as EditOutlinedIcon } from '@material-ui/icons';
import {
  Button,
  Col,
  Drawer,
  Form,
  Popover,
  Row,
  Space,
  Typography,
} from 'antd';
import React, { useCallback, useMemo } from 'react';

import { ResponsiveQuery, useResponsive, useToggle } from 'hooks';
import { Note } from 'types';

import { ProgressNumber } from './ProgressNumber';
import { TriggerWrapper } from './styles';
import { useNoteMutations } from '../../hooks';

type FormModel = {
  newSeason: number;
  newEpisode: number;
};

type Props = {
  note: Note;
};

export const NoteShowProgress: React.FC<Props> = ({ note }) => {
  const mobile = useResponsive(ResponsiveQuery.Mobile);

  const { currentSeason, currentEpisode } = note;

  const { updateNoteSeasonAndEpisode } = useNoteMutations();
  const { isOpen, toggle } = useToggle();

  const [form] = Form.useForm<FormModel>();

  const handleEdit = useCallback(() => {
    form.setFieldsValue({
      newSeason: note.currentSeason || 1,
      newEpisode: note.currentEpisode || 1,
    });
    toggle();
  }, [form, note.currentEpisode, note.currentSeason, toggle]);

  const handleSubmit = useCallback(async () => {
    const data = await form.validateFields();
    updateNoteSeasonAndEpisode.mutate({
      ...data,
      id: note.id,
    });
    toggle();
  }, [form, note.id, toggle, updateNoteSeasonAndEpisode]);

  const ProgressForm = useMemo(
    () => () => (
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Сезоне" name="newSeason">
              <ProgressNumber />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Эпизоде" name="newEpisode">
              <ProgressNumber />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ),
    [form],
  );

  const Trigger = useMemo(
    () => () => (
      <TriggerWrapper onClick={handleEdit}>
        <Typography.Text type="secondary">
          {!currentSeason || !currentEpisode ? (
            <span>?</span>
          ) : (
            <span>
              {currentSeason} сезон / {currentEpisode} серия
            </span>
          )}
        </Typography.Text>

        <EditOutlinedIcon style={{ color: '#a3a3a3' }} fontSize="small" />
      </TriggerWrapper>
    ),
    [currentEpisode, currentSeason, handleEdit],
  );

  return mobile ? (
    <>
      <Trigger />

      <Drawer
        open={isOpen}
        title={
          <>
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              {note.title}
            </Typography.Title>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              Остановились на
            </Typography.Text>
          </>
        }
        placement="bottom"
        onClose={toggle}
        footer={
          <Button type="primary" onClick={handleSubmit} block>
            Сохранить
          </Button>
        }
        closeIcon={null}
        height={300}
      >
        <ProgressForm />
      </Drawer>
    </>
  ) : (
    <Popover
      title={
        <div style={{ padding: 12, paddingBottom: 0 }}>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            Остановились на
          </Typography.Text>
        </div>
      }
      content={
        <Space
          style={{ padding: 12, paddingTop: 0 }}
          direction="vertical"
          size={16}
          align="end"
        >
          <ProgressForm />
          <Button type="primary" onClick={handleSubmit}>
            Сохранить
          </Button>
        </Space>
      }
      open={isOpen}
      placement="bottomLeft"
      trigger="click"
      onOpenChange={toggle}
    >
      <Trigger />
    </Popover>
  );
};
