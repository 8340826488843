import { NoteOptions } from 'constants/note.constants';

export const DELETE_NOTE_KEY = 'delete';

export const NOTE_SETTINGS_ITEMS = NoteOptions.map(({ value, label }) => ({
  label,
  key: value,
}));

export const DELETABLE_NOTE_SETTINGS_ITEMS = [
  {
    type: 'divider',
  } as any,
  {
    key: DELETE_NOTE_KEY,
    label: 'Удалить',
    danger: true,
  },
];
