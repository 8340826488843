import { ThemeConfig } from 'antd/es/config-provider/context';

// TODO customize site's theme
export const ThemeConf: ThemeConfig = {
  token: {
    colorPrimary: '#2e1ebe',
    // borderRadius: 16,
  },
  components: {
    Popover: {
      marginXS: 20,
    },
    Form: {
      fontSize: 12,
    },
    // Button: {
    //   paddingContentHorizontal: 20,
    //   controlHeight: 40,
    // },
    // Tag: {
    //   lineWidth: 0,
    // },
  },
  // algorithm: [theme.darkAlgorithm]
};
