import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { notesApi } from 'api/notes';
import { MediaType, QueryKey } from 'enums';
import { useUser } from 'hooks';
import { Note } from 'types';

export const useNotes = (mediaType: MediaType) => {
  const { isLoggedIn } = useUser();

  const notesQuery = useQuery({
    queryKey: [QueryKey.Notes, mediaType],
    queryFn: () => notesApi.getNotes({ type: mediaType }),
    enabled: isLoggedIn,
  });
  const { data: notes } = notesQuery;

  const notesAccessibleByMediaId: Record<number, Note> = useMemo(
    () =>
      (notes || []).reduce(
        (acc: {}, item: Note) => ({ ...acc, [item.mediaId]: item }),
        {},
      ),
    [notes],
  );

  const getNoteByMediaId = useCallback(
    (mediaId: number) => notesAccessibleByMediaId?.[mediaId],
    [notesAccessibleByMediaId],
  );

  return {
    getNoteByMediaId,
  };
};
