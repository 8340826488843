export enum ModalNames {
  Login = 'modal-login',
  Register = 'modal-register',

  GlobalSearch = 'modal-search',

  Media = 'modal-media',

  Navigation = 'modal-navigation',
}
