import { MediaType } from 'enums';
import { MediaList } from 'features/media';

import { useTmdbFilters } from '../../movies/MoviesListView';

const type = MediaType.Show;

export const ShowsListView = () => {
  const filterDefinitions = useTmdbFilters(type);
  return <MediaList type={type} filterDefinitions={filterDefinitions} />;
};
