import { Tag, Image } from 'antd';
import React from 'react';

import { FALLBACK_IMAGE } from 'constants/image.constants';
import {
  MediaMainData,
  MediaScore,
  MediaScoreShape,
  MediaScoreSize,
  MediaSettings,
} from 'features/media';
import { Media } from 'types';

import {
  CardWrapper,
  ContentWrapper,
  MediaContent,
  NoteScoreWrapper,
  NoteStatusWrapper,
} from './styles';

type Props = {
  media: Media;
  onClick?: () => void;
  hideTitle?: boolean;
};

// TODO improve MediaCard
// rethink ifs
export const MediaCard: React.FC<Props> = ({ media, onClick, hideTitle }) => (
  <CardWrapper>
    <ContentWrapper>
      <MediaContent>
        <NoteScoreWrapper>
          {media.releaseDate && new Date(media.releaseDate) < new Date() ? (
            <MediaScore
              score={media.score}
              size={MediaScoreSize.small}
              shape={MediaScoreShape.rectangle}
              withBg
            />
          ) : (
            <Tag color="geekblue">ожидается</Tag>
          )}
        </NoteScoreWrapper>

        <Image
          src={media.pathToPoster}
          onClick={onClick}
          width="100%"
          height="100%"
          preview={false}
          alt="poster"
          fallback={FALLBACK_IMAGE}
        />

        <NoteStatusWrapper>
          <MediaSettings media={media} />
        </NoteStatusWrapper>
      </MediaContent>
    </ContentWrapper>

    {!hideTitle && (
      <MediaMainData
        item={media}
        titleProps={{
          strong: true,
        }}
        onClick={onClick}
      />
    )}
  </CardWrapper>
);
