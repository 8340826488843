import { YearRangeValueFormat } from 'constants/form.constants';
import { PAGE_SIZE } from 'constants/pagination.constants';
import { Note, NoteFiltersModel } from 'types';
import { formatDate } from 'utils';

import { NOTE_LIST_STATUS_MAP, NoteListTab } from './constants';

export const filterNotesByTab = (
  notes: Note[] | undefined,
  tab: NoteListTab,
): Note[] => {
  if (!notes) {
    return [];
  }

  if (tab === NoteListTab.All) {
    return notes;
  }

  if (tab === NoteListTab.Favorites) {
    return notes.filter((note) => Boolean(note.like));
  }

  const status = NOTE_LIST_STATUS_MAP[tab];
  return notes.filter((note) => note.status === status);
};

export const filterNotesByFilters = (
  notes: Note[] | undefined,
  filters: NoteFiltersModel | null,
): Note[] => {
  if (!notes) {
    return [];
  }

  let result = notes;

  if (!filters) {
    return result;
  }

  const { rating, year } = filters;

  if (rating) {
    result = result.filter(
      (note) =>
        note.score && note.score >= rating[0] && note.score <= rating[1],
    );
  }

  if (year) {
    result = result.filter((note) => {
      if (!note.releaseDate) {
        return true;
      }
      const noteYear = +formatDate(note.releaseDate, YearRangeValueFormat);
      return noteYear >= +year[0] && noteYear <= +year[1];
    });
  }

  return result;
};

export const getNoteNumber = (index: number, page: number) =>
  index + 1 + PAGE_SIZE * (page - 1);
