import { Col, Row, Typography } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { MediaTypesRU } from 'constants/media';
import { useMediaType } from 'features/media';
import { useResponsive, ResponsiveQuery } from 'hooks';

import { AccountMenu } from './components';

export const AccountView = () => {
  const mobile = useResponsive(ResponsiveQuery.Mobile);

  const mediaType = useMediaType();

  return mobile ? (
    <Row>
      <Col span={24}>
        <Typography.Title level={4}>{MediaTypesRU[mediaType]}</Typography.Title>
      </Col>
      <Col span={24}>
        <Outlet />
      </Col>
    </Row>
  ) : (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <AccountMenu />
      </Col>
      <Col span={18}>
        <Outlet />
      </Col>
    </Row>
  );
};
