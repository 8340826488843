import { Typography } from 'antd';
import React, { FC } from 'react';

import { LogoIcon, Wrapper } from './styles';

type Props = {
  textSize?: 1 | 2 | 3 | 4 | 5;
  type?: 'secondary';
  onClick?: () => void;
};

export const Logo: FC<Props> = ({ textSize = 5, type, onClick }) => (
  <Wrapper onClick={onClick}>
    <LogoIcon />

    <Typography.Title type={type} level={textSize} style={{ margin: 0 }}>
      Lenno
    </Typography.Title>
  </Wrapper>
);
