import { axiosInstance } from 'config';

import {
  AuthBaseResponse,
  PostLoginRqParams,
  PostRegisterRqParams,
} from './types';

const login = (data: PostLoginRqParams): Promise<AuthBaseResponse> =>
  axiosInstance.post('auth', data);

const register = (data: PostRegisterRqParams): Promise<AuthBaseResponse> =>
  axiosInstance.post('register', data);

const logout = (): Promise<void> => axiosInstance.post('logout', {});

export const authApi = {
  login,
  logout,
  register,
};
