import { Card, Col, Row } from 'antd';
import React, { FC } from 'react';

import { ModalNames } from 'enums';
import { MediaMainData, MediaSettings } from 'features/media';
import { useModalContext } from 'hooks';
import { SearchedMedia } from 'types';

import { ResultWrapper } from './styles';

type Props = {
  items: SearchedMedia[];
};

export const SearchResult: FC<Props> = ({ items }) => {
  const modalContext = useModalContext();
  return (
    <Row gutter={[8, 8]}>
      {items.map((item) => (
        <Col span={24} key={item.id}>
          <Card size="small">
            <ResultWrapper>
              <MediaMainData
                item={item}
                onClick={() =>
                  modalContext.openModal(ModalNames.Media, {
                    mediaId: item.id,
                    type: item.mediaType,
                  })
                }
              />
              <MediaSettings media={item} />
            </ResultWrapper>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
