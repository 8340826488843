import { Menu } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Logo } from 'components';
import { CatalogMediaLinks } from 'constants/catalog-media-links.constants';
import { ModalNames } from 'enums';
import { useModalContext } from 'hooks';
import Modal from 'styled/modal';

import { LogoWrapper, MenuWrapper } from './style';

export const ModalNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const modalContext = useModalContext();
  const handleClose = useCallback(
    () => modalContext.closeModal(ModalNames.Navigation),
    [modalContext],
  );

  const menuItems = useMemo(
    () =>
      CatalogMediaLinks.map(({ label, link }) => ({
        key: link,
        label,
        onClick: () => {
          navigate(link);
          handleClose();
        },
      })),
    [handleClose, navigate],
  );

  return (
    <Modal onCancel={handleClose} footer={null} fullScreen open>
      <LogoWrapper>
        <Logo textSize={3} />
      </LogoWrapper>

      <MenuWrapper>
        <Menu
          mode="inline"
          items={menuItems}
          selectedKeys={[location.pathname]}
        />
      </MenuWrapper>
    </Modal>
  );
};
