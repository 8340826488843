import { Tag } from 'antd';
import styled from 'styled-components';

export const CloseButton = styled(Tag)`
  cursor: pointer;
`;

export const ContentWrapper = styled.div<{ mobile?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.mobile ? 'calc(100vh - 40px)' : '400px')};
`;

export const InputWrapper = styled.div`
  margin-bottom: 20px;

  & > div {
    width: 100%;
  }
`;

export const ResultWrapper = styled.div`
  overflow: scroll;
`;
