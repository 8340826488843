import { useQuery } from '@tanstack/react-query';
import { Col, Row, Typography } from 'antd';
import React, { FC, useMemo } from 'react';

import { catalogApi, GetMediaResponse } from 'api/catalog';
import { DataWrapper } from 'components';
import { MediaTypesRU } from 'constants/media';
import { MediaType, ModalNames, QueryKey } from 'enums';
import { MediaCard } from 'features/media';
import {
  Filters,
  QueryPagination,
  usePagination,
  useQueryFilters,
  SelectedFilters,
} from 'features/query-filters';
import { useModalContext } from 'hooks';
import { FilterDefinition } from 'types';

import { MediaListSkeleton } from './MediaListSkeleton';

interface Props {
  type: MediaType;
  filterDefinitions?: FilterDefinition[];
}

export const MediaList: FC<Props> = ({ type, filterDefinitions }) => {
  const modalContext = useModalContext();

  const { filters, selectedFiltersCount } = useQueryFilters();
  const { pagination } = usePagination();

  const catalogRqParams = {
    type,
    ...pagination,
    ...filters,
  };

  const catalogQuery = useQuery({
    queryKey: [QueryKey.Catalog, catalogRqParams],
    queryFn: () => catalogApi.getMedia(catalogRqParams),
    keepPreviousData: true,
  });
  const { data: catalog } = catalogQuery;

  const isFilterable = useMemo(() => !!filterDefinitions, [filterDefinitions]);

  return (
    <Row gutter={[24, 16]}>
      <Col span={24}>
        <Row justify="space-between">
          <Typography.Title level={4} type="secondary">
            {MediaTypesRU[type]}
          </Typography.Title>

          {isFilterable && (
            <Filters filterDefinitions={filterDefinitions || []} />
          )}
        </Row>
      </Col>

      {selectedFiltersCount !== 0 && (
        <Col span={24}>
          <SelectedFilters definitions={filterDefinitions || []} />
        </Col>
      )}

      <Col sm={24}>
        <DataWrapper<GetMediaResponse>
          queryResult={catalogQuery}
          initialLoadingView={<MediaListSkeleton />}
          getLoadedData={(data) => data?.media}
        >
          <Row gutter={[16, 16]}>
            {catalog?.media?.map((media) => (
              <Col key={media.id} xs={12} sm={8} md={6}>
                <MediaCard
                  media={media}
                  onClick={() =>
                    modalContext.openModal(ModalNames.Media, {
                      mediaId: media.id,
                      type: media.mediaType,
                    })
                  }
                />
              </Col>
            ))}

            <Col span={24}>
              <Row justify="center">
                <QueryPagination total={catalog?.total} />
              </Row>
            </Col>
          </Row>
        </DataWrapper>
      </Col>
    </Row>
  );
};
