import { Grid } from 'antd';

export enum ResponsiveQuery {
  Mobile,
  Tablet,
  Desktop,
}

export const useResponsive = (query: ResponsiveQuery) => {
  const screens = Grid.useBreakpoint();

  if (query === ResponsiveQuery.Desktop) {
    return screens.lg;
  }

  if (query === ResponsiveQuery.Tablet) {
    return screens.md && !screens.lg;
  }

  if (query === ResponsiveQuery.Mobile) {
    return screens.xs || (screens.sm && !screens.md);
  }

  return false;
};
