import { axiosInstance } from 'config';
import { Note } from 'types';

import { MapMediaTypeId } from './constants';
import {
  GetNotesRqParams,
  AddNoteRqParams,
  UpdateNoteStatusRqParams,
  UpdateNoteLikeRqParams,
  DeleteNoteRqParams,
  UpdateNoteSeasonEpisodeRqParams,
  UpdateNoteCommentRqParams,
} from './types';

const getNotes = ({ type }: GetNotesRqParams): Promise<Note[]> =>
  axiosInstance.get(`/${type}/notes`).then((data: any) =>
    data[type].map((media: Partial<Note> & any) => ({
      ...media,
      mediaType: type,
      mediaId: media[MapMediaTypeId[type]],
    })),
  );

const addNotes = ({ ids, status, type }: AddNoteRqParams) =>
  axiosInstance.post(`/${type}/notes/add`, {
    items_ids: ids,
    status,
  });

const updateNoteStatus = ({ id, newStatus }: UpdateNoteStatusRqParams) =>
  axiosInstance.post('/notes/change_status', {
    note_id: id,
    status: newStatus,
  });

const updateNoteLike = ({ id, newLike }: UpdateNoteLikeRqParams) =>
  axiosInstance.post('/notes/like', {
    note_id: id,
    like: newLike,
  });

const deleteNote = ({ id }: DeleteNoteRqParams) =>
  axiosInstance.delete('/notes/delete', {
    data: {
      note_id: id,
    },
  });

const updateNoteSeasonAndEpisode = ({
  id,
  newSeason,
  newEpisode,
}: UpdateNoteSeasonEpisodeRqParams) =>
  axiosInstance.post('/notes/change_season_episodes', {
    note_id: id,
    current_season: newSeason,
    current_episode: newEpisode,
  });

const updateNoteComment = ({ id, comment }: UpdateNoteCommentRqParams) =>
  axiosInstance.post('/notes/comment/edit', {
    note_id: id,
    comment,
  });

export const notesApi = {
  getNotes,
  addNotes,
  updateNoteStatus,
  updateNoteComment,
  updateNoteLike,
  updateNoteSeasonAndEpisode,
  deleteNote,
};
