import { Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

type ModalProps = AntModalProps & { fullScreen?: boolean };

const Modal: FC<ModalProps> = styled(AntModal)<ModalProps>`
  ${(props) =>
    props.fullScreen &&
    `
    width: 100vw;
    height: 100vh;
    
    margin: 0;
    top: 0;
  
    .ant-modal-content {
      width: 100vw;
      height: 100vh;
      
      overflow: auto;
      
      border-radius: 0;
    }
  
    // .ant-modal-body {
    //   height: calc(100vh - 110px);
    // }
  `}
`;

export default Modal;
