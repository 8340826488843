import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PAGE_QUERY } from '../../../constants/pagination.constants';

type Props<T> = {
  queryParamKey: string;
  initialValue?: T;
  resetPagination?: boolean;
};

export function useQueryParam<T>({
  queryParamKey,
  initialValue,
  resetPagination,
}: Props<T>): {
  queryParam: T;
  setQueryParam: (value?: T) => void;
} {
  const [queryParam, setQueryParam] = useState<T>(initialValue as any as T);

  const location = useLocation();
  const navigate = useNavigate();

  const setLocalFilters = useCallback(
    (search: string) => {
      const query = new URLSearchParams(search).get(queryParamKey);
      setQueryParam((query ? query : initialValue) as any as T);
    },
    [initialValue, queryParamKey],
  );

  const handleSetQueryParam = useCallback(
    (value?: T) => {
      const searchParams = new URLSearchParams(location.search);

      if (value) {
        searchParams.set(queryParamKey, String(value));
      } else {
        searchParams.delete(queryParamKey);
      }

      if (resetPagination) {
        searchParams.delete(PAGE_QUERY);
      }

      navigate({ search: searchParams.toString() });
    },
    [location.search, navigate, queryParamKey, resetPagination],
  );

  useEffect(
    () => setLocalFilters(location.search),
    [location, setLocalFilters],
  );

  return {
    queryParam,
    setQueryParam: handleSetQueryParam,
  };
}
