import { useQuery } from '@tanstack/react-query';

import { catalogApi } from 'api';
import { MediaType, QueryKey } from 'enums';

export const useGenres = (type: MediaType) =>
  useQuery({
    queryKey: [QueryKey.Genres, type],
    queryFn: () => catalogApi.getMediaGenres({ type }),
    select: (data) =>
      (data || []).map((g: any) => ({ value: g.id, label: g.name })),
    initialData: [],
  });
