import styled from 'styled-components';

export const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  position: relative;
  padding-bottom: calc(100% * 4.5 / 3);

  margin-bottom: 4px;
`;

export const MediaContent = styled.div`
  background-color: rgba(0, 0, 0, 0.11);

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  border-radius: 5px;
  overflow: hidden;

  cursor: pointer;
`;

export const NoteScoreWrapper = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;

  z-index: 1;
`;

export const NoteStatusWrapper = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
`;

export const NoteLikeWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;
