import { MediaType } from 'enums';
import { Media } from 'types';

export const getMediaPosterURL = (media: Media) => {
  let url = media.pathToPoster || '';
  if (
    media.pathToPoster &&
    (media.mediaType === MediaType.Movie || media.mediaType === MediaType.Show)
  ) {
    url = `https://image.tmdb.org/t/p/w300${media.pathToPoster}`;
  }
  return `${process.env.REACT_APP_SERVER_API_URL}image/proxy?url=${url}`;
};
