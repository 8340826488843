import { Button, Drawer, Popover, Row, Space, Typography } from 'antd';
import React, { FC, useCallback } from 'react';

import { ResponsiveQuery, useResponsive, useToggle } from 'hooks';
import { FilterDefinition } from 'types';

import { FiltersToggle } from './FiltersToggle';
import { SelectedFilters } from './SelectedFilters';
import { useQueryFiltersForm } from './use-query-filters-form.hook';
import { useQueryFilters } from '../../hooks';

type Props = {
  filterDefinitions: FilterDefinition[];
  showSelectedFilters?: boolean;
};

export const Filters: FC<Props> = ({
  filterDefinitions,
  showSelectedFilters,
}) => {
  const mobile = useResponsive(ResponsiveQuery.Mobile);

  const { resetFilters, selectedFiltersCount } = useQueryFilters();

  const { onSubmit, component: QueryFiltersForm } = useQueryFiltersForm({
    definitions: filterDefinitions,
  });

  const { isOpen, toggle } = useToggle();

  const handleReset = useCallback(() => {
    resetFilters();
    toggle();
  }, [resetFilters, toggle]);

  const handleSubmit = useCallback(() => {
    onSubmit();
    toggle();
  }, [onSubmit, toggle]);

  return (
    <Row justify="space-between">
      {showSelectedFilters && (
        <SelectedFilters definitions={filterDefinitions} />
      )}

      {mobile ? (
        <>
          <FiltersToggle onClick={toggle} />

          <Drawer
            open={isOpen}
            title="Фильтры"
            placement="bottom"
            onClose={toggle}
            {...(selectedFiltersCount !== 0 && {
              extra: (
                <Button onClick={handleReset} size="small">
                  Сбросить
                </Button>
              ),
            })}
            footer={
              <Button onClick={handleSubmit} type="primary" block>
                Применить
              </Button>
            }
            closeIcon={null}
            height={500} // TODO Drawer too small when there is a lot of information
          >
            <QueryFiltersForm />
          </Drawer>
        </>
      ) : (
        <Popover
          title={
            <div style={{ padding: 12, paddingBottom: 0 }}>
              <Row justify="space-between" align="middle">
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  Фильтры
                </Typography.Text>

                {selectedFiltersCount !== 0 && (
                  <Button onClick={handleReset} size="small">
                    Сбросить
                  </Button>
                )}
              </Row>
            </div>
          }
          content={
            <Space
              style={{ padding: 12, paddingTop: 0 }}
              direction="vertical"
              size={8}
              align="end"
            >
              <QueryFiltersForm />
              <Button onClick={handleSubmit} type="primary">
                Применить
              </Button>
            </Space>
          }
          trigger="click"
          open={isOpen}
          onOpenChange={toggle}
          placement="leftTop"
        >
          <FiltersToggle />
        </Popover>
      )}
    </Row>
  );
};
