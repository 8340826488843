import { storageUtils } from './storage.utils';

const TOKEN_KEY = 'LN-TOKEN';

export type AuthData = {
  token: string;
};

let authData: AuthData = null as any as AuthData;

const getAuthData = () => authData || storageUtils.getItem(TOKEN_KEY);

const setAuthData = (data: AuthData) => {
  authData = data;
  storageUtils.setItem(TOKEN_KEY, authData);
};

const resetAuthData = () => {
  authData = null as any as AuthData;
  storageUtils.removeItem(TOKEN_KEY);
};

const isLoggedIn = () => !!getAuthData();

const getAccessToken = () => getAuthData()?.token;

export const authUtils = {
  getAuthData,
  setAuthData,
  resetAuthData,
  isLoggedIn,
  getAccessToken,
};
