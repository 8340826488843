import { Typography } from 'antd';

import { Wrapper } from './styles';

export const Footer = () => (
  <Wrapper>
    <Typography.Text>
      #tochk #lenok © 2016 - {new Date().getFullYear()}{' '}
    </Typography.Text>
  </Wrapper>
);
