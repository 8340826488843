import { PAGE_QUERY } from 'constants/pagination.constants';

import { useQueryParam } from './use-query-param.hook';

type PaginationState = {
  page: number;
};

export function usePagination(): {
  pagination: PaginationState;
  setPagination: (pageNumber?: number) => void;
} {
  const { queryParam, setQueryParam } = useQueryParam<number>({
    queryParamKey: PAGE_QUERY,
    initialValue: 1,
  });

  return {
    pagination: {
      page: +queryParam,
    },
    setPagination: setQueryParam,
  };
}
