import { find } from 'lodash';
import { useMemo } from 'react';

import { FilterDefinitionType, MediaType } from 'enums';
import { useGenres, useUser } from 'hooks';
import { FilterDefinition } from 'types';

export const useTmdbFilters = (type: MediaType) => {
  const { isLoggedIn } = useUser();
  const { data: genres } = useGenres(type);

  const definitions: FilterDefinition[] = useMemo(
    () => [
      {
        label: 'Рейтинг',
        name: 'rating',
        type: FilterDefinitionType.Rating,
        initialValue: [0, 10],
      },
      {
        label: 'Год выпуска',
        name: 'year',
        type: FilterDefinitionType.YearRange,
      },
      {
        label: 'Жанр',
        name: 'genreIds',
        type: FilterDefinitionType.Genre,
        genres,
        getDisplayName: (value: string) => find(genres, { value })?.label,
      },
      ...((isLoggedIn
        ? [
            {
              label: 'Скрыть "неинтересное"',
              name: 'notInteresting',
              type: FilterDefinitionType.Switch,
            },
          ]
        : []) as FilterDefinition[]),
    ],
    [isLoggedIn, genres],
  );

  return definitions;
};
