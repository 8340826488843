import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Divider, Form, Input, Space, Typography } from 'antd';

import { authApi } from 'api/auth';
import { Logo } from 'components';
import { ModalNames } from 'enums';
import {
  useModalContext,
  useResponsive,
  ResponsiveQuery,
  useUserContext,
} from 'hooks';
import Modal from 'styled/modal';

interface FormModel {
  login: string;
  email: string;
  password1: string;
  password2: string;
}

export const ModalRegister = () => {
  const userContext = useUserContext();

  const mobile = useResponsive(ResponsiveQuery.Mobile);
  const modalContext = useModalContext();

  const [form] = Form.useForm<FormModel>();

  const register = useMutation({
    mutationFn: authApi.register,
    onSuccess: (data) => {
      userContext.setToken(data.token);
      handleClose();
    },
  });

  const handleClose = () => modalContext.closeModal(ModalNames.Register);

  return (
    <Modal
      width={350}
      onCancel={handleClose}
      footer={null}
      fullScreen={mobile}
      centered={!mobile}
      destroyOnClose
      open
    >
      <Space style={{ width: '100%' }} direction="vertical">
        <Logo type="secondary" />

        <Space style={{ width: '100%' }} direction="vertical" align="center">
          <UserOutlined
            style={{
              fontSize: 50,
              marginBottom: '20px',
              color: '#00000073',
            }}
          />
          <Typography.Title level={4}>Регистрация</Typography.Title>
          <Typography.Text
            type="secondary"
            style={{
              fontSize: 12,
            }}
          >
            для продолжения заполните форму
          </Typography.Text>
        </Space>

        {/* TODO add component with errors */}
        {/*<ErrorComponent/>*/}

        <Form
          form={form}
          layout="vertical"
          onFinish={(data: FormModel) =>
            register.mutate({
              login: data.login,
              email: data.email,
              password: data.password1,
            })
          }
        >
          <Form.Item
            label="Логин"
            name="login"
            rules={[
              { required: true, message: 'Логин обязателен!' },
              { min: 4, message: 'Слишком короткий! Минимум 4 символа' },
            ]}
          >
            <Input placeholder="user" />
          </Form.Item>

          <Form.Item
            label="Почта"
            name="email"
            rules={[
              { required: true, message: 'Email обязателен!' },
              { type: 'email', message: 'Некорректный email' },
            ]}
          >
            <Input placeholder="user@gmail.com" />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password1"
            rules={[
              { required: true, message: 'Пароль обязателен!' },
              { min: 5, message: 'Слишком короткий! Минимум 5 символа' },
            ]}
          >
            <Input.Password placeholder="******" />
          </Form.Item>

          <Form.Item
            label="Повторите пароль"
            name="password2"
            dependencies={['password1']}
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password1') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Пароли не совпадают'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="******" />
          </Form.Item>

          <Form.Item noStyle>
            <Button
              htmlType="submit"
              type="primary"
              loading={register.isLoading}
              block
            >
              Зарегистрироваться
            </Button>
          </Form.Item>

          <Form.Item noStyle>
            <Divider plain> Уже есть аккаунт? </Divider>
          </Form.Item>

          <Form.Item noStyle>
            <Button
              onClick={() => {
                handleClose();
                modalContext.openModal(ModalNames.Login);
              }}
              block
            >
              Авторизоваться
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};
