import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { profileApi } from 'api';
import { QueryKey } from 'enums';
import { User } from 'types';
import { authUtils } from 'utils';

export type UserContextProps = {
  user?: User | null;
  isLoggedIn: boolean;
  setToken: (token: string) => void;
  reset: () => void;
};

const UserContext = createContext<UserContextProps>({} as UserContextProps);

type UserProviderProps = {
  children: ReactNode;
};

function UserProvider({ children }: UserProviderProps) {
  const queryClient = useQueryClient();

  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(authUtils.getAccessToken());

  useQuery({
    queryKey: [QueryKey.Profile],
    queryFn: profileApi.me,
    onSuccess: setUser,
    enabled: !!token,
  });

  const isLoggedIn = useMemo(() => Boolean(token || user), [token, user]);

  const handleSetToken = useCallback(
    (token: string) => {
      authUtils.setAuthData({ token });
      setToken(token);
      queryClient.invalidateQueries([QueryKey.Profile]);
    },
    [queryClient],
  );

  const reset = useCallback(() => {
    authUtils.resetAuthData();
    setToken(null);
    setUser(null);
  }, []);

  return (
    <UserContext.Provider
      value={{ user, isLoggedIn, setToken: handleSetToken, reset }}
    >
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };
