import styled from 'styled-components';

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 25px;
  margin-bottom: 70px;
`;

export const MenuWrapper = styled.div`
  margin-bottom: 25px;

  text-align: center;

  & > ul {
    border-inline-end: none !important;
  }
`;
