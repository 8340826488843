import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Typography, Dropdown, Space } from 'antd';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { authApi } from 'api';
import { AccountMediaLinks } from 'constants/account-media-links.contants';
import { useUser, useUserContext } from 'hooks';
import IconButton from 'styled/icon-button';

export const AccountMenu = () => {
  const location = useLocation();

  const { user } = useUser();
  const { reset } = useUserContext();

  const logout = useMutation({
    mutationFn: authApi.logout,
    onSuccess: reset,
  });

  const items = useMemo(
    () => [
      {
        key: 'username',
        label: (
          <>
            <Typography.Text>{user?.login}</Typography.Text>
            <br />
            <Typography.Text type="secondary">{user?.email}</Typography.Text>
          </>
        ),
        disabled: true,
      },
      {
        type: 'divider',
      } as any,
      {
        key: 'header-saves',
        label: (
          <Typography.Text type="secondary"> Мои сохранения </Typography.Text>
        ),
        disabled: true,
      },
      ...AccountMediaLinks,
      {
        type: 'divider',
      } as any,
      {
        key: 'logout',
        label: (
          <Space>
            <LogoutOutlined />
            <span>Выйти</span>
          </Space>
        ),
        onClick: logout.mutate,
      },
    ],
    [logout, user],
  );

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items,
        selectable: true,
        selectedKeys: [location.pathname],
      }}
    >
      <IconButton icon={<UserOutlined />} />
    </Dropdown>
  );
};
