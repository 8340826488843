import { Card, Menu } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { AccountMediaLinks } from 'constants/account-media-links.contants';

const menuItems = [
  { label: 'Сохраненные', type: 'group', children: AccountMediaLinks },
  {
    label: 'Разное',
    type: 'group',
    children: [{ label: 'Настройки', disabled: true }],
  },
];

export const AccountMenu = () => {
  const location = useLocation();

  return (
    <Card size="small">
      <Menu
        style={{ borderRight: 0 }}
        mode="inline"
        items={menuItems}
        selectedKeys={[location.pathname]}
      />
    </Card>
  );
};
