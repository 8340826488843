import { Card, Space, Typography } from 'antd';
import React from 'react';

import { MediaType } from 'enums';
import {
  MediaMainData,
  MediaScore,
  MediaScoreSize,
  MediaScoreShape,
} from 'features/media';
import {
  NoteSettings,
  NoteShowProgress,
  NoteComment,
} from 'features/notes/components';
import { Note } from 'types';

import { ContentWrapper, InfoWrapper } from './styles';
import { NoteLike } from '../NoteLike';

type Props = {
  index: number;
  note: Note;
  onNameClick?: () => void;
};

export const NoteRow: React.FC<Props> = ({ index, note, onNameClick }) => (
  <Card size="small">
    <ContentWrapper>
      <Space direction="horizontal" align="start">
        <Typography.Text type="secondary" style={{ whiteSpace: 'nowrap' }}>
          {index}.
        </Typography.Text>

        <div>
          <MediaMainData item={note} onClick={onNameClick} />
          <MediaScore
            score={note.score}
            size={MediaScoreSize.small}
            shape={MediaScoreShape.line}
            withQuestionWhenNotDefined
            withIcon
          />
        </div>
      </Space>

      <Space direction="horizontal" align="center">
        <NoteSettings note={note} showStatusText />

        {/* TODO move NoteLike component into NoteStatus component for mobile view */}
        <NoteLike note={note} />
      </Space>
    </ContentWrapper>

    {note.mediaType !== MediaType.Movie && (
      <InfoWrapper>
        <NoteShowProgress note={note} />
      </InfoWrapper>
    )}

    <InfoWrapper>
      <NoteComment note={note} />
    </InfoWrapper>
  </Card>
);
