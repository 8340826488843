import { CloseOutlined } from '@material-ui/icons';
import { useQuery } from '@tanstack/react-query';
import React, { FC } from 'react';

import { catalogApi } from 'api/catalog';
import { DataWrapper } from 'components/DataWrapper';
import { MediaType, ModalNames, QueryKey } from 'enums';
import { MediaCard, MediaMainData } from 'features/media';
import { useModalContext, useResponsive, ResponsiveQuery } from 'hooks';
import IconButton from 'styled/icon-button';
import Modal from 'styled/modal';

import { MediaSkeleton } from './MediaSkeleton';
import {
  MediaWrapper,
  DescriptionWrapper,
  CloseButtonWrapper,
  Wrapper,
  Description,
} from './styles';

type Props = {
  type: MediaType;
  mediaId: number;
};

// TODO ModalMedia: split component into 2 (desktop / mobile)
export const ModalMedia: FC<Props> = ({ mediaId, type }) => {
  const mobile = useResponsive(ResponsiveQuery.Mobile);
  const modalContext = useModalContext();

  const queryResult = useQuery<any>({
    queryKey: [QueryKey.Catalog, type, mediaId],
    queryFn: () => catalogApi.getMediaById({ type, id: Number(mediaId) }),
  });
  const { data: media } = queryResult;

  const handleClose = () => modalContext.closeModal(ModalNames.Media);

  return (
    <Modal
      width={750}
      onCancel={handleClose}
      closable={false}
      footer={null}
      fullScreen={mobile}
      centered={!mobile}
      destroyOnClose
      open
    >
      <CloseButtonWrapper mobile={mobile}>
        <IconButton icon={<CloseOutlined />} onClick={handleClose} />
      </CloseButtonWrapper>

      <DataWrapper
        queryResult={queryResult}
        initialLoadingView={<MediaSkeleton />}
      >
        <Wrapper mobile={mobile}>
          <MediaWrapper>
            <MediaCard media={media} hideTitle={!mobile} />
          </MediaWrapper>

          <DescriptionWrapper mobile={mobile}>
            {!mobile && (
              <MediaMainData
                item={media}
                titleProps={{
                  strong: true,
                }}
              />
            )}
            <Description>{media?.overview}</Description>
          </DescriptionWrapper>
        </Wrapper>
      </DataWrapper>
    </Modal>
  );
};
