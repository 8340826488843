import styled from 'styled-components';

import { ReactComponent as LogoSVG } from './assets/logo.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${(props) => props.onClick && `cursor: pointer;`}
`;

export const LogoIcon = styled(LogoSVG)`
  width: 35px;
  height: 35px;

  margin-right: 10px;
`;
